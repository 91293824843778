var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DraftContainer',{ref:"draftContainer",attrs:{"id":_vm.$route.params.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var draft = ref.draft;
var status = ref.status;
var error = ref.error;
var deleteDraft = ref.deleteDraft;
return [_c('div',{staticClass:"DraftScene"},[_c('Modal',{ref:"confirmDeleteDialog",attrs:{"title":"Slet ordre","working":status === _vm.Progress.WORKING},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('p',[_vm._v("Er du sikker på du vil slette dette tilbud?")])]},proxy:true},{key:"controls",fn:function(ref){
var close = ref.close;
return [_c('Button',{attrs:{"type":"light"},on:{"click":close}},[_vm._v("Fortryd")]),_c('Button',{attrs:{"color":_vm.ButtonColors.RED,"icon":_vm.ButtonIcons.TRASH},on:{"click":function () { return deleteDraft().then(close); }}},[_vm._v(" Slet ")])]}}],null,true)}),_c('CartContainer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isEmpty = ref.isEmpty;
var refresh = ref.refresh;
var quantity = ref.quantity;
return [_c('Modal',{ref:"confirmTransferDialog",attrs:{"title":"Overfør til kurv","working":status === _vm.Progress.WORKING},on:{"open":refresh},scopedSlots:_vm._u([{key:"default",fn:function(){return [(isEmpty)?_c('p',[_vm._v("Er du sikker på du vil overfører tilbuddet til kurven?")]):_c('p',[_vm._v(_vm._s(_vm.$tc('items_already_in_cart', quantity, { count: quantity })))])]},proxy:true},{key:"controls",fn:function(ref){
var close = ref.close;
return [(isEmpty)?[_c('Button',{attrs:{"type":"light"},on:{"click":close}},[_vm._v("Fortryd")]),_c('Button',{attrs:{"color":_vm.ButtonColors.CTA,"icon":_vm.ButtonIcons.ADD_CART},on:{"click":function () { return _vm.transferToCart(false); }}},[_vm._v(" Overfør ")])]:[_c('Button',{attrs:{"type":"light"},on:{"click":function () { return _vm.transferToCart(false); }}},[_vm._v("Fjern")]),_c('Button',{attrs:{"color":_vm.ButtonColors.CTA},on:{"click":function () { return _vm.transferToCart(true); }}},[_vm._v("Behold")])]]}}],null,true)})]}}],null,true)}),(status === _vm.Progress.ERROR)?[(error === _vm.DraftErrors.NOT_FOUND)?_c('Alert',{attrs:{"level":_vm.AlertLevel.WARNING,"title":"Kladden blev ikke fundet"}},[_vm._v(" Enten er den blevet overført til kurven eller slettet. ")]):_c('Alert',{attrs:{"level":_vm.AlertLevel.ERROR}},[_vm._v(" Der skete en "),_c('strong',[_vm._v("fejl")]),_vm._v(". Vi kunne desværre ikke åbne den pågældende kladde. Prøv igen senere. ")])]:_c('div',[_c('div',{staticClass:"DraftHead"},[_c('PageBar',{staticClass:"PageBar",attrs:{"disabled":status !== _vm.Progress.COMPLETE,"controls":[
            { name: _vm.$t('print'), action: _vm.printPage, icon: _vm.ButtonIcons.PRINT },
            {
              name: _vm.$t('transfer_to_cart'),
              color: _vm.ButtonColors.DEFAULT,
              icon: _vm.ButtonIcons.ADD_CART,
              action: _vm.openTransferDialog,
              disabled: draft && draft.read_only,
            },
            {
              name: _vm.$t('delete'),
              color: _vm.ButtonColors.RED,
              icon: _vm.ButtonIcons.TRASH,
              action: _vm.openConfirmDeleteDialog,
            } ]}}),_c('div',{staticClass:"DraftHead__header"},[_c('SkeletonTransition',{attrs:{"ready":status === _vm.Progress.COMPLETE},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('h1',{staticClass:"DraftHead__heading"},[_vm._v(_vm._s(_vm.$t('draft.type.' + draft.type)))])]},proxy:true},{key:"skeleton",fn:function(){return [_c('SkeletonGraphic',{attrs:{"width":83,"height":39}})]},proxy:true}],null,true)})],1),_c('DraftHead')],1),_c('SkeletonTransition',{attrs:{"ready":status === _vm.Progress.COMPLETE},scopedSlots:_vm._u([{key:"default",fn:function(){return [(draft && draft.lines.length)?_c('Table',{staticClass:"Order__table",attrs:{"itemKey":"line_id","items":draft.lines}},[_c('TableColumn',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [_c('ImageComponent',{staticClass:"Order__tableImage",attrs:{"src":item.product.image,"alt":item.product.title}})]}}],null,true)}),_c('TableColumn',{attrs:{"header":"Varenummer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.item_number)+" ")]}}],null,true)}),_c('TableColumn',{attrs:{"header":"Product"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.title)+" ")]}}],null,true)}),_c('TableColumn',{attrs:{"header":"Stk. pris","format":_vm.ColumnFormat.PRICE},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.item_price)+" ")]}}],null,true)}),_c('TableColumn',{attrs:{"header":"Antal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity)+" ")]}}],null,true)}),_c('TableColumn',{attrs:{"header":"Brutto","format":_vm.ColumnFormat.PRICE},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.brutto)+" ")]}}],null,true)}),_c('TableColumn',{attrs:{"header":"Beløb","format":_vm.ColumnFormat.PRICE},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.total_amount)+" ")]}}],null,true)})],1):_vm._e()]},proxy:true},{key:"skeleton",fn:function(){return [_c('TableSkeleton',{staticClass:"Order__table",attrs:{"rows":3}},[_c('SkeletonGraphic',{attrs:{"width":"50px","height":"40px"}}),_c('SkeletonGraphic',{attrs:{"width":"60px","height":"20px"}}),_c('SkeletonGraphic',{attrs:{"width":"40%","height":"20px"}}),_c('SkeletonGraphic',{attrs:{"width":"50px","height":"20px"}}),_c('SkeletonGraphic',{attrs:{"width":"50px","height":"20px"}}),_c('SkeletonGraphic',{attrs:{"width":"50px","height":"20px"}}),_c('SkeletonGraphic',{attrs:{"width":"50px","height":"20px"}})],1)]},proxy:true}],null,true)})],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
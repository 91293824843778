<template>
  <ul class="VehicleList">
    <li v-for="vehicle in vehicles" :key="vehicle.id">
      <a :href="vehicle.url" :title="vehicle.description" class="VehicleList__name">{{
        vehicle.description
      }}</a>
      <div class="VehicleList__details">
        <div>Regnr.: {{ vehicle.license_plate }}</div>
        <div>TecDoc ID.: {{ vehicle.id }}</div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'VehicleList',
  components: {},
  props: {
    vehicles: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.VehicleList {
  display: flex;
  flex-direction: column;
  gap: 1em;
  .VehicleList__details {
    font-weight: normal;
  }
}
</style>

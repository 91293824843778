<template>
  <div class="OrderHead">
    <div class="OrderHead__main"></div>
    <SkeletonTransition class="OrderHead__sidebar" :ready="!!draftContainer.draft">
      <template #default>
        <Details :data="details" />
      </template>
      <template #skeleton>
        <DetailsSkeleton :items="4" />
      </template>
    </SkeletonTransition>
  </div>
</template>

<script>
import { SkeletonTransition, DetailsSkeleton } from '@components/Skeleton';
import Details, { Formats } from '@scenes/SelfserviceScene/components/Details';

// Templates
import VehiclesList from './components/VehiclesList';

export default {
  name: 'DraftHead',

  components: {
    SkeletonTransition,
    DetailsSkeleton,
    Details,
  },

  computed: {
    details() {
      return [
        {
          header: 'Navn',
          label: this.draftContainer.draft.description,
        },
        {
          header: 'Oprettet',
          label: this.draftContainer.draft.created,
          format: Formats.DATE,
        },
        {
          header: 'Redigeret',
          label: this.draftContainer.draft.edited,
          format: Formats.DATE,
        },
        this.draftContainer.draft.system_name && {
          header: 'Kundestyring',
          label: this.draftContainer.draft.system_name,
        },
        this.draftContainer.draft.worksheet_number && {
          header: 'Arbejdskort nr.',
          label: this.draftContainer.draft.worksheet_number,
        },
        this.draftContainer.draft.customer_reference && {
          header: 'Kunde nr.', // Kommer ikke fra kundestyring pt! Kim siger det kan tilføjes. Skal det på?
          label: this.draftContainer.draft.customer_reference,
        },
        this.draftContainer.draft.vehicles &&
          this.draftContainer.draft.vehicles.length > 0 && {
            header: this.$tc('car', this.draftContainer.draft.vehicles.length),
            span: 2,
            template: VehiclesList,
            templateProps: { vehicles: this.draftContainer.draft.vehicles },
          },
      ];
    },
  },

  inject: {
    draftContainer: {
      default() {
        console.error('Missing DraftContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.OrderHead {
  display: flex;
  flex-flow: column;

  @media screen AND (min-width: $tablet) {
    flex-flow: row;
  }
}

.OrderHead__main {
  flex: 1;
  @media screen AND (min-width: $laptopL) {
    flex: 1.2;
  }
}

.OrderHead__sidebar {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>

<template>
  <DraftContainer
    :id="$route.params.id"
    ref="draftContainer"
    v-slot="{ draft, status, error, deleteDraft }"
  >
    <div class="DraftScene">
      <Modal ref="confirmDeleteDialog" title="Slet ordre" :working="status === Progress.WORKING">
        <template #default>
          <p>Er du sikker på du vil slette dette tilbud?</p>
        </template>
        <template #controls="{ close }">
          <Button type="light" @click="close">Fortryd</Button>
          <Button
            :color="ButtonColors.RED"
            :icon="ButtonIcons.TRASH"
            @click="() => deleteDraft().then(close)"
          >
            Slet
          </Button>
        </template>
      </Modal>
      <CartContainer v-slot="{ isEmpty, refresh, quantity }">
        <Modal
          ref="confirmTransferDialog"
          title="Overfør til kurv"
          :working="status === Progress.WORKING"
          @open="refresh"
        >
          <template #default>
            <p v-if="isEmpty">Er du sikker på du vil overfører tilbuddet til kurven?</p>
            <p v-else>{{ $tc('items_already_in_cart', quantity, { count: quantity }) }}</p>
          </template>
          <template #controls="{ close }">
            <template v-if="isEmpty">
              <Button type="light" @click="close">Fortryd</Button>
              <Button
                :color="ButtonColors.CTA"
                :icon="ButtonIcons.ADD_CART"
                @click="() => transferToCart(false)"
              >
                Overfør
              </Button>
            </template>
            <template v-else>
              <Button type="light" @click="() => transferToCart(false)">Fjern</Button>
              <Button :color="ButtonColors.CTA" @click="() => transferToCart(true)">Behold</Button>
            </template>
          </template>
        </Modal>
      </CartContainer>

      <template v-if="status === Progress.ERROR">
        <Alert
          v-if="error === DraftErrors.NOT_FOUND"
          :level="AlertLevel.WARNING"
          title="Kladden blev ikke fundet"
        >
          Enten er den blevet overført til kurven eller slettet.
        </Alert>
        <Alert v-else :level="AlertLevel.ERROR">
          Der skete en <strong>fejl</strong>. Vi kunne desværre ikke åbne den pågældende kladde.
          Prøv igen senere.
        </Alert>
      </template>

      <div v-else>
        <div class="DraftHead">
          <PageBar
            class="PageBar"
            :disabled="status !== Progress.COMPLETE"
            :controls="[
              { name: $t('print'), action: printPage, icon: ButtonIcons.PRINT },
              {
                name: $t('transfer_to_cart'),
                color: ButtonColors.DEFAULT,
                icon: ButtonIcons.ADD_CART,
                action: openTransferDialog,
                disabled: draft && draft.read_only,
              },
              {
                name: $t('delete'),
                color: ButtonColors.RED,
                icon: ButtonIcons.TRASH,
                action: openConfirmDeleteDialog,
              },
            ]"
          ></PageBar>

          <div class="DraftHead__header">
            <SkeletonTransition :ready="status === Progress.COMPLETE">
              <template #default>
                <h1 class="DraftHead__heading">{{ $t('draft.type.' + draft.type) }}</h1>
              </template>
              <template #skeleton>
                <SkeletonGraphic :width="83" :height="39" />
              </template>
            </SkeletonTransition>
          </div>
          <DraftHead />
        </div>

        <SkeletonTransition :ready="status === Progress.COMPLETE">
          <template #default>
            <Table
              class="Order__table"
              v-if="draft && draft.lines.length"
              itemKey="line_id"
              :items="draft.lines"
            >
              <TableColumn v-slot="{ item }">
                <ImageComponent
                  class="Order__tableImage"
                  :src="item.product.image"
                  :alt="item.product.title"
                />
              </TableColumn>
              <TableColumn v-slot="{ item }" header="Varenummer">
                {{ item.product.item_number }}
              </TableColumn>
              <TableColumn v-slot="{ item }" header="Product">
                {{ item.product.title }}
              </TableColumn>
              <TableColumn v-slot="{ item }" header="Stk. pris" :format="ColumnFormat.PRICE">
                {{ item.item_price }}
              </TableColumn>
              <TableColumn v-slot="{ item }" header="Antal">
                {{ item.quantity }}
              </TableColumn>
              <TableColumn v-slot="{ item }" header="Brutto" :format="ColumnFormat.PRICE">
                {{ item.brutto }}
              </TableColumn>
              <TableColumn v-slot="{ item }" header="Beløb" :format="ColumnFormat.PRICE">
                {{ item.total_amount }}
              </TableColumn>
            </Table>
          </template>
          <template #skeleton>
            <TableSkeleton class="Order__table" :rows="3">
              <SkeletonGraphic width="50px" height="40px" />
              <SkeletonGraphic width="60px" height="20px" />
              <SkeletonGraphic width="40%" height="20px" />
              <SkeletonGraphic width="50px" height="20px" />
              <SkeletonGraphic width="50px" height="20px" />
              <SkeletonGraphic width="50px" height="20px" />
              <SkeletonGraphic width="50px" height="20px" />
            </TableSkeleton>
          </template>
        </SkeletonTransition>
      </div>
    </div>
  </DraftContainer>
</template>

<script>
import DraftContainer, {
  Errors as DraftErrors,
} from '@scenes/SelfserviceScene/scenes/DraftScene/containers/DraftContainer';
import CartContainer from '@containers/CartContainer';
import PageBar from '@scenes/SelfserviceScene/components/PageBar';
import { Table, TableColumn, ColumnFormat } from '@components/Table';
import ImageComponent from '@components/ImageComponent';
import Progress from '@types/Progress';
import { Modal } from '@components/Modal';
import {
  Button,
  sizes as ButtonSizes,
  colors as ButtonColors,
  icons as ButtonIcons,
} from '@components/Buttons';
import DraftHead from './components/DraftHead';
import Alert, { AlertLevel } from '@components/Alert';
import {
  SkeletonTransition,
  TableSkeleton,
  SkeletonGraphic,
  ButtonSkeleton,
} from '@components/Skeleton';

export default {
  name: 'DraftScene',

  components: {
    Button,
    DraftContainer,
    CartContainer,
    ImageComponent,
    PageBar,
    Modal,
    DraftHead,
    Table,
    TableColumn,
    Alert,

    SkeletonGraphic,
    SkeletonTransition,
    TableSkeleton,
    SkeletonGraphic,
    ButtonSkeleton,
  },

  enums: {
    ButtonSizes,
    ButtonColors,
    ButtonIcons,
    ColumnFormat,
    Progress,
    DraftErrors,
    AlertLevel,
  },

  methods: {
    openConfirmDeleteDialog() {
      this.$refs.confirmDeleteDialog.open();
    },
    printPage() {
      window.print();
    },
    openTransferDialog() {
      this.$refs.confirmTransferDialog.open();
    },
    transferToCart(preserveLines = true) {
      this.$refs.draftContainer
        .transferToCart({ preserveLines, deleteDonor: true })
        .then(response => {
          window.location.replace('/shoppingcart');
        })
        .catch(response => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.DraftHead__heading {
  font-size: 30px;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0.2em;
}

.DraftHead__subheading {
  color: #a8a8a8;
  font-size: 16px;
}

.DraftHead__header {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.Order__table {
  margin-top: 30px;
}

.Order__tableImage {
  max-width: 90px;
}

.AmountSummary {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 20px;

  @media (max-width: $laptopSMax) {
    margin-top: 0;
    margin-bottom: 40px;
  }
}

.AmountSummary__line {
  display: flex;
  margin-bottom: 7px;

  &:last-child {
    margin-bottom: 0;
  }

  > div {
    font-size: 1.15em;

    &:first-child {
      width: 80px;
      padding-left: 7px;
    }

    &:last-child {
      width: 170px;
      text-align: right;
      padding-right: calc(21px + 15px);
      font-weight: bold;
    }
  }
}

@mixin printStyles() {
  .DraftHead {
    .DraftHead__heading {
      margin-bottom: 0;
      font-size: 20px;
    }

    .DraftHead__subheading {
      font-size: 55px;
    }
  }
}

.DraftScene {
  padding: 0 20px;

  @media screen AND (min-width: $laptopS) {
    padding: 0;
  }
}

@media print {
  @include printStyles();
}

.ForcedPrint {
  @include printStyles();
}
</style>

<i18n>
  {
    "da": {
      "transfer_to_cart": "Overfør til kurv",
      "items_already_in_cart": "Der er ingen varer i kurven | Der er allerede én vare i kurven, hvad vil du gøre med den? | Der er allerede {count} varer i kurven, hvad vil du gøre med dem?"
    }
  }
</i18n>

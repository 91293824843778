<template>
  <div class="PageBar">
    <div class="PageBar__breadcrumb">
      <template v-if="previousRoute">
        <router-link :to="{ name: previousRoute.name }">
          {{ previousRoute.meta.title }}
        </router-link>
      </template>
    </div>
    <div v-if="false" class="PageBar__jumpto"></div>
    <div v-if="hasControls" class="PageBar__controls">
      <slot name="controls">
        <template v-for="(control, index) in controls">
          <template v-if="control">
            <Button
              v-if="control.action"
              :key="index"
              class="PageBar__control"
              :color="control.color !== undefined ? control.color : buttonColors.WHITE"
              :icon="control.icon"
              :size="buttonSizes.SMALL"
              @click="control.action"
              :disabled="disabled || control.disabled"
            >
              {{ control.name }}
            </Button>
            <Button
              v-else-if="control.download"
              :key="index"
              tag="a"
              :href="control.download"
              :download="control.download"
              :disabled="disabled || control.disabled"
              class="PageBar__control"
              :color="control.color !== undefined ? control.color : buttonColors.WHITE"
              :icon="control.icon"
              :size="buttonSizes.SMALL"
              >{{ control.name }}</Button
            >
          </template>
        </template>
      </slot>
    </div>
  </div>
</template>

<script>
import {
  Button,
  sizes as buttonSizes,
  colors as buttonColors,
  icons as buttonIcons,
} from '@components/Buttons';

export default {
  name: 'PageBar',

  components: {
    Button,
  },

  props: {
    previousRoute: {
      type: Object,
      default: null,
    },
    controls: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasControls() {
      return (
        this.controls.length > 0 || !!this.$slots['controls'] || !!this.$scopedSlots['controls']
      );
    },
  },

  created() {
    this.buttonIcons = buttonIcons;
    this.buttonColors = buttonColors;
    this.buttonSizes = buttonSizes;
  },
};
</script>

<style lang="scss" scoped>
.PageBar {
  display: flex;
  border-bottom: 1px solid $color-prim-grey;
  margin-bottom: 20px;
  justify-content: space-between;
  padding-bottom: 20px;

  .PageBar__breadcrumb {
    flex: 1;
  }

  .PageBar__controls {
    align-self: flex-end;
    margin-right: -10px;
  }

  .PageBar__control {
    margin-right: 10px;
  }
}
</style>

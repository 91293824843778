import { Actions, Getters } from '@store/modules/cart';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'CartContainer',

  props: {},

  computed: {
    ...mapState('cart', ['cart']),
    ...mapGetters('cart', {
      isEmpty: Getters.IS_EMPTY,
      quantity: Getters.QUANTITY,
    }),
  },

  created() {
    this.refresh();
  },

  methods: {
    ...mapActions('cart', {
      refresh: Actions.FETCH_CART,
    }),
  },

  render() {
    return this.$scopedSlots.default({
      lines: this.cart.lines,
      isEmpty: this.isEmpty,
      refresh: this.refresh,
      quantity: this.quantity,
    });
  },

  provide() {
    return {
      CartContainer: this,
    };
  },
};

import { getDraft, deleteDraft, postTransformType } from '@services/thansen/drafts';

import Progress from '@types/Progress';
import DraftTypes from '@types/DraftTypes';

const Errors = {
  NOT_FOUND: 'NOT_FOUND',
};
export { Errors };
export default {
  name: 'DraftContainer',
  data() {
    return {
      draft: null,
      status: '',
      error: '', // Errors
    };
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {},
  created() {
    if (this.id) {
      this.loadDraft();
    }
  },
  methods: {
    loadDraft(id = this.id) {
      this.status = Progress.WORKING;

      getDraft(id)
        .then(draft => {
          if (!draft) {
            this.error = Errors.NOT_FOUND;
            this.status = Progress.ERROR;
          } else {
            this.draft = draft;
            this.status = Progress.COMPLETE;
          }
        })
        .catch(error => {
          this.status = Progress.ERROR;
          //this.error = ErrorsEnum.NOT_FOUND
        });
    },
    transferToCart(options = {}) {
      return new Promise((resolve, reject) => {
        postTransformType(options.id || this.id, DraftTypes.CART, {
          preserve_lines: options.preserveLines === true,
          delete_donor: options.deleteDonor === true,
        })
          .then(draft => {
            this.status = Progress.WORKING;
            resolve();
          })
          .catch(error => {
            this.status = Progress.ERROR;
            reject();
            //this.error = ErrorsEnum.NOT_FOUND
          });
      });
    },
    deleteDraft(id = this.id) {
      return new Promise((resolve, reject) => {
        this.status = Progress.WORKING;
        deleteDraft(id)
          .then(draft => {
            this.status = Progress.ERROR;
            this.draft = null;
            this.error = Errors.NOT_FOUND;
            resolve();
          })
          .catch(error => {
            this.status = Progress.ERROR;
            reject();
          });
      });
    },
  },

  render() {
    return this.$scopedSlots.default({
      draft: this.draft,
      status: this.status,
      error: this.error,
      transferToCart: this.transferToCart,
      deleteDraft: this.deleteDraft,
    });
  },
  provide() {
    return { draftContainer: this };
  },
};
